<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <h2 class="card-title">{{ $t('titlePages.transactionalEmailStatistics') }}</h2>
        <div class="card card-chart">
          <div class="card-header">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <div class="col-md-4">
                      <base-input :error="getError($t('table.columns.StartDate'))" :label="$t('table.columns.StartDate')">
                        <el-date-picker
                          v-model="StartDate"
                          type="date"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('table.columns.StartDate').toUpperCase()"
                          :name="$t('table.columns.StartDate')"
                          v-validate="'required'"
                          :picker-options="pickerOptions"
                        ></el-date-picker>
                      </base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input :error="getError($t('table.columns.EndDate'))" :label="$t('table.columns.EndDate')">
                        <el-date-picker
                          v-model="EndDate"
                          type="date"
                          value-format="yyyy-MM-dd"
                          :placeholder="$t('table.columns.EndDate').toUpperCase()"
                          :name="$t('table.columns.EndDate')"
                          v-validate="'required'"
                          :picker-options="pickerOptions"
                        ></el-date-picker>
                      </base-input>
                    </div>
                  </div>
                </div>

                <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
                  <base-button
                    v-if="$apollo.queries.statsMGWithMail_dateInterval.loading"
                    loading
                    class="btn btn-just-icon btn-default mt-4 mr-3 d-block float-right"
                  >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                  <button v-else class="btn btn-just-icon btn-default mt-4 d-block float-right" @click="send">{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-chart">
          <div class="card-header">
            <div class="row">
              <div class="col-12 text-left">
                <!-- <h2 class="card-title">Globales</h2> -->
              </div>
            </div>
          </div>
          <div class="p-2" data="black">
            <el-table :data="tableLeads" show-summary :summary-method="getSummaries" v-loading="$apollo.queries.statsMGWithMail_dateInterval.loading">
              <el-table-column label="tag" prop="date" min-width="250">
                <template scope="scope">
                  <el-tooltip
                    :content="scope.row.description"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <label>
                      {{ scope.row.tag.toUpperCase() }}
                    </label>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="Impressions" prop="impressions" min-width="150" sortable>
                <template scope="scope">{{ (scope.row.impressions === null) ? 'NC' : formatThounsends(scope.row.impressions)}}</template>
              </el-table-column>
              <el-table-column :label="$t('table.columns.clicks')" prop="clics" min-width="100" sortable>
                <template scope="scope">{{ formatThounsends(scope.row.clics) }}</template>
              </el-table-column>
              <el-table-column label="Leads" prop="leads" min-width="100" sortable>
                <template scope="scope">
                  <label>{{ (scope.row.leads === null) ? 'NC' : formatThounsends(scope.row.leads)}}</label>
                </template>
              </el-table-column>
              <el-table-column :label="$t('table.columns.emailSent')" prop="nbr_email" min-width="100" sortable>
                <template scope="scope">{{ (scope.row.nbr_email === null) ? 'NC' : formatThounsends(scope.row.nbr_email)}}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker } from "element-ui";
import gql from "graphql-tag";
import { Modal } from "src/components";
import { Table, TableColumn } from "element-ui";

let thisday = new Date().getFullYear() + "-"+ (new Date().getUTCMonth() + 1) + "-" + new Date().getUTCDate()
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      showBarChart: false,
      StartDate: thisday,
      EndDate: thisday,
      tagRequired: '',
      SkipQuery: true,
      SUMimpressions: 0,
      SUMleads: 0,
      SUMclics: 0,
      SUMnbrEmail:0,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      showExtractModal: false,
      firstEntryToPage: true,
      listTags: {
        tags:  [ 
          "Suggestions-sante_Mon gustave Sante_Visuel",
          "Suggestions-sante_Mon Gustave Animaux_Visuel",
          "Suggestions-sante_Mon Gustave Auto_Visuel",
          "Suggestions-sante_Assur emprunt_Visuel1",
          "Suggestions-sante_Mon Gustave MRH_Visuel",
          "Suggestions-animaux_Mon Gustave Animaux_Visuel",
          "Suggestions-animaux_Mon gustave Sante_Visuel",
          "Suggestions-animaux_Mon Gustave Auto_Visuel",
          "Suggestions-animaux_Assur emprunt_Visuel1",
          "Suggestions-animaux_Mon Gustave MRH_Visuel",
          "Suggestions-mrh_Mon Gustave MRH_Visuel",
          "Suggestions-mrh_Mon Gustave Animaux_Visuel",
          "Suggestions-mrh_Mon gustave Sante_Visuel",
          "Suggestions-mrh_Mon Gustave Auto_Visuel",
          "Suggestions-mrh_Assur emprunt_Visuel1",
          "Anniverssaire-prospect_Mon Gustave Animaux_Visuel",
          "Anniverssaire-prospect_Mon gustave Sante_Visuel",
          "Anniverssaire-prospect_Mon Gustave Auto_Visuel",
          "Anniverssaire-prospect_Mon Gustave MRH_Visuel",
          "Anniverssaire-prospect_Assur emprunt_Visuel1",
          "Anniversaire-Animaux_Mon Gustave Animaux_Visuel",
          "Anniversaire-Animaux_Mon gustave Sante_Visuel",
          "Anniversaire-Animaux_Mon Gustave Auto_Visuel",
          "Anniversaire-Animaux_Mon Gustave MRH_Visuel",
          "Anniversaire-Animaux_Assur emprunt_Visuel1",
          "Avis-google-MG_Mon Gustave Animaux_Visuel",
          "Avis-google-MG_Mon gustave Sante_Visuel",
          "Avis-google-MG_Mon Gustave Auto_Visuel",
          "Avis-google-MG_Mon Gustave MRH_Visuel",
          "Avis-google-MG_Assur emprunt_Visuel1",
          "Abandon-MG-LM_Mon gustave Sante_Visuel",
          "Abandon-MG-LM_Mon Gustave Animaux_Visuel",
          "Abandon-MG-LM_Mon Gustave Auto_Visuel",
          "Abandon-MG-LM_Assur emprunt_Visuel1",
          "Abandon-MG-LM_Mon Gustave MRH_Visuel"
        ],
        tag: ""
      },
      selectTag: null,
      tableLeads: [],
      //leadsTotals: [],
    };
  },
  apollo: {
    statsMGWithMail_dateInterval: {
      query: gql`
        query($date_start: Date!, $date_end: Date!) {
          statsMGWithMail_dateInterval(
            date_start: $date_start, date_end: $date_end
          ) {
            tag
            description
            impressions
            clics
            leads
            nbr_email
          }
        }
      `,
      skip(){
        return (this.SkipQuery)
      },
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {
        this.tableLeads = data.statsMGWithMail_dateInterval;
        let SUMimpressions = 0
        let SUMclics = 0
        let SUMleads = 0
        let SUMnbrEmail = 0
        
        for (let index = 0; index < data.statsMGWithMail_dateInterval.length; index++) {
          SUMimpressions += data.statsMGWithMail_dateInterval[index].impressions
          SUMleads += data.statsMGWithMail_dateInterval[index].leads
          SUMclics += data.statsMGWithMail_dateInterval[index].clics
          SUMnbrEmail += data.statsMGWithMail_dateInterval[index].nbr_email
        }
        this.SUMimpressions = SUMimpressions
        this.SUMleads = SUMleads
        this.SUMclics = SUMclics  
        this.SUMnbrEmail = SUMnbrEmail    
        this.SkipQuery = true;
      },
      variables() {
        return {
          date_start: this.StartDate,
          date_end: this.EndDate
        };
      }
    }
  },
  methods: {
    async send() {
      this.SkipQuery = false
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "";
          return;
        } else if (index === 1) {
          sums[index] = this.formatThounsends(this.SUMimpressions)
          return;
        } else if (index === 2) {
          sums[index] = this.formatThounsends(this.SUMclics)
          return;
        } else if (index === 3) {
          sums[index] = this.formatThounsends(this.SUMleads)
          return;
        } else if (index === 4) {
          sums[index] = this.formatThounsends(this.SUMnbrEmail)
          return;
        }
      });
      return sums;
    },
    Details(row) {
      row.jour = row.jour.split(' ')[3] + '/' + (this.mounth.indexOf(row.jour.split(' ')[2]) + 1) + '/' + row.jour.split(' ')[1]
      row.id_client = this.$route.params.id_client 
      row.id_programme = null
      row.editeur = this.societeClient || null
      localStorage.setItem("Details", JSON.stringify(row))
      this.$router.push({ name: "stats Detail Leads day"})
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      value != null ? (value = parseFloat(value)) : (value = 0);
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    checkIfNull(value) {
      return value == null ? 0 : value;
    }
  },
  watch: {
    'listTags.tag'() {
      if(this.listTags.tag == "") {
        this.tagRequired = 'required'
      }
      else {
        this.tagRequired = ''
      }
    }
  }
};
</script>